@import "../../common/theme_variables";
@import "../../mixins/transitions";
@import "../../mixins/shadows";


.shopping_cart{
    display: none;
}

#header_nav{
    padding: 0;
    margin: 0;
    position: absolute;
    top: 10px;
    right: 15px;
    z-index: 2;
    list-style-type: none;
    @media(max-width:991px){
        top: 0;
        right: 15px;
    }

    & > li{
        display: inline-block;
        margin-left: 15px;
        @media(min-width:992px){
            position: relative; 
        }

        @media(max-width:991px){
            margin-left: 8px;
        }

        & > a{
            display: inline-block;
            color: $black;
            font-size: 30px;
            line-height: 80px;
            height: 60px;
            position: relative;

            .ajax_cart_quantity{
                border-radius: 100%;
                background: $golden;
                color: $white;
                font-size: 10px;
                line-height: 1;
                height: 18px;
                padding: 5px;
                box-sizing: border-box;
                text-align: center;
                display: inline-block;
                -moz-box-shadow: 0px 0px 0px 2px $white;
                -webkit-box-shadow: 0px 0px 0px 2px $white;
                -o-box-shadow: 0px 0px 0px 2px $white;
                box-shadow: 0px 0px 0px 2px $white;
                position: absolute;
                top: 20px;
                right: -5px;
                z-index: 1;           
            }

            &:hover,
            &:focus{
                text-decoration: none;
            }
        }

        ul{
            display: none;
            list-style-type: none;
            position: absolute;
            top: 100%;
            right: 0;
            z-index: 10;
            padding: 20px;
            background: $grey;
            border-top: $golden solid 4px;
            @include shadows(2px,2px,0,0px,rgba(0,0,0,0.1));

            @media(min-width:992px){
                min-width: 300px;

                &#header_user_info{
                    right: -50px;
                }
            }

            @media(max-width:991px){
                width: calc(100vw - 30px);
            }

            &#header_user_info{
                a{
                    display: block;
                    &:not(.btn){
                        color: $golden;
                        font-family: $font-semibold;
                        font-size: 18px;
                    }
                    &.btn{
                        margin-top: 15px;
                    }
                }
            }

            &#header_user_cart{
                a{
                    display: block;
                    &.btn{
                        margin-top: 15px;
                    }
                }
                .ajax_cart_quantity{
                    color: $golden;
                    font-family: $font-semibold;
                    font-size: 18px;
                }
                .ajax_cart_total{
                    display: block;
                    padding-top: 15px;
                    margin-top: 15px;
                    border-top: lighten($black,65%) solid 1px;
                    font-family: $font-semibold;
                    font-size: 24px;
                }
            }

        }

        @media(max-width:767px){
            &.open ul{
                display: block;
            }
        }
        @media(min-width:768px){
            &:hover ul{
                display: block;
            }
        }
    }    
}